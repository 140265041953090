import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    avatar: '',
    emailOrPhone: '',
    firstName: '',
    lastName: '',
    accountStatus: '',
    accountVerify: '',
    email: '',
    lastLogin: '',
    phoneNumber: '',
    twoFactorAuth: '',
    twoFactorAuthQrCode: '',
    createdAt: '',
    authority: [],
    roles: [],
    company: {},
    wallet: {},
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        // setUser: (_, action) => action.payload,
        setUser: (state, action) => {
            console.log('setUser Action Payload:', action.payload);
            state.avatar = action.payload.avatar;
            state.emailOrPhone = action.payload.email;
            state.email = action.payload.email;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.accountStatus = action.payload.status;
            state.accountVerify = action.payload.status;
            state.phoneNumber = action.payload.phoneNumber;
            state.twoFactorAuthQrCode = '';
            state.createdAt = action.payload.createdAt;
            state.twoFactorAuth = false;
            state.twoFactorAuthVerified = false;
            state.roles = action.payload.role;
            state.balance = action.payload.balance;
            if (action.payload.role == "ADMIN") {
                state.authority = ['ROLE_ADMIN']
            } else if (action.payload.role == "USER") {
                state.authority = ['ROLE_USER']
            }

        },
        userLoggedOut: () => initialState,
    },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
